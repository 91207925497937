<script>
import { mapActions } from "vuex";
import ALoading from "../components/atoms/a-loading.vue";
import fetch from "@utils/fetch";
import objToQueryString from "@utils/obj-to-query-string";
import axios from "axios";

export default {
  name: "Checkout",
  components: {
    ALoading,
  },

  mounted() {
    this.resetModal();
    this.clearOrder("");
    this.clearCatalog();
    const currentHost =
      process.env.NODE_ENV === "development"
        ? "carrinho.assine.com.br"
        : window.location.hostname;
    const utm_referrer =
      window.document.referrer !== "" ? window.document.referrer : "direto";
    const {
      IdParceiro,
      idParceiro,
      idparceiro,
      ...routerQueries
    } = this.$route.query;
    const _IdParceiro = idParceiro || idparceiro || IdParceiro;
    const queries = {
      ...routerQueries,
      utm_referrer,
      IdParceiro: _IdParceiro,
    };
    if (!queries.IdParceiro && !currentHost)
      queries.IdParceiro = "1322deb0-9d8e-4047-b08a-c90572277e59";

    if (currentHost && !queries.IdParceiro) queries.systemUrl = currentHost;
    this.setQueriesParams(queries);
    this.setSelectedPlan(queries.sku);
    this.setAddress({ cep: queries.cep, numero: queries.numero });
    this.setPersonalData({ telefone: queries.cel });
    this.setIsStoreStarted();
    this.handleAutoFetch(queries);
    this.routerOrchestror(queries);
    // this.$tracking.send().finally(() => {
    // });
  },
  methods: {
    ...mapActions({
      resetModal: "modal/resetModal",
      clearOrder: "order/clearData",
      clearCatalog: "catalog/clearData",
      setCatalog: "catalog/setCatalog",
      setOrderId: "order/setOrderId",
      setSelectedPlan: "catalog/setSelectedPlan",
      setQueriesParams: "setQueriesParams",
      setAddress: "order/setAddress",
      setPersonalData: "order/setPersonalData",
      setIsStoreStarted: "setIsStoreStarted",
      fetchApi: "api",
      generalError: "ui/setIsGeneralError",
      messageError: "ui/setMessageError",
    }),

    async routerOrchestror({
      identificadorPedido,
      IdentificadorPedido,
      ...queries
    }) {
      this.$store.dispatch("ui/setOrderStatus", false);
      if (identificadorPedido || IdentificadorPedido) {
        await this.orchestror(queries);
        await this.handleRetomada(
          identificadorPedido || IdentificadorPedido,
          queries.sku
        );
      } else if (
        queries.sku &&
        queries.numero &&
        queries.cep &&
        queries.lead === "0"
      ) {
        const { retorno } = await this.orchestror(queries);
        const config = retorno.parceiroConfiguracao;
        const {
          data: { portfolio },
        } = await this.getCatalog({ url: config.catalogo });

        const newConfigObj = config.configuracoesOperadora.reduce(
          (acc, curr) => {
            const newCurr = Object.assign({ ...curr });
            delete newCurr.operadora;
            return Object.assign(acc, {
              [curr.operadora.toLowerCase()]: { ...newCurr },
            });
          },
          {}
        );

        this.$store.dispatch("order/setBillConfig", newConfigObj);
        this.$store.dispatch("catalog/setCatalog", { portfolio });
        return this.$router.push("/dados-pessoais");
      } else {
        await this.orchestror(queries);
        if (queries.redirect) return await this.$router.push(queries.redirect);
        return this.$router.push("/consulta-cobertura");
      }
    },
    async handleRetomada(identificadorPedido, sku) {
      try {
        // WDIK7F7M5A
        this.generalError(false);
        let payload = { identificadorPedido };
        if (sku) {
          payload = { identificadorPedido, sku };
        }
        await fetch(
          this.fetchApi,
          "api",
          "Retomada",
          objToQueryString(payload),
          response => {
            if (response.error) throw new Error("Error de timeout excedido");
            if (response.sucesso) {
              const { cliente } = response;

              this.setOrderId(identificadorPedido);
              this.saveClient(cliente);
              this.setCatalog({ portfolio: { fixa: response.catalogo } });
              this.$store.dispatch(
                "order/setBillConfig",
                response.configuracoes
              );
              if (response.skuProduto)
                this.setSelectedPlan(response.skuProduto);
              this.$store.dispatch(
                "ui/setOrderStatus",
                response.urlRedirect.toLowerCase() === "/parabens"
              );
              if (response.urlRedirect)
                this.$router.push(response.urlRedirect.toLowerCase());
            } else {
              this.$router.push("/consulta-cobertura");
            }
          }
        );
      } catch (error) {
        this.generalError(true);
      }
    },
    handleAutoFetch({ cel, numero, cep }) {
      if (cel && numero && cep)
        this.$store.dispatch("ui/setAutoFetchStatus", true);
    },
    async orchestror({ IdParceiro, systemUrl, ...queries }) {
      try {
        const payload = {
          IdParceiro: systemUrl ? null : IdParceiro ? IdParceiro : null,
          systemUrl: IdParceiro ? null : systemUrl ? systemUrl : null,
        };

        this.generalError(false);

        return await fetch(
          this.fetchApi,
          "api",
          "ConsultaParceiro",
          payload,
          response => {
            if (response.error) {
              throw new Error("Erro de timeout excedido.");
            }
            if (response.sucesso) {
              const config = {
                ...response.parceiroConfiguracao,
                partnerId: response.idParceiro,
              };
              const favicon = config.favicon;
              const logo = config.logo;
              const title = config.title;
              const css = config.css;
              const gtm = config.gtm ?? process.env.VUE_APP_GTM;
              const idPartner = config.idParceiro;
              const esteiraConfiguracao = config.esteiraConfiguracao;

              this.addTitle(title);
              this.addLogo(logo);
              this.addCss(css);
              this.addFavicon(favicon);
              this.addGTM(gtm, "gtm-config");
              this.setQueriesParams({
                ...queries,
                systemUrl,
                IdParceiro: response.idParceiro,
              });
              this.saveParceiroConfig(config);
              this.addCustomClarityTag("parceiroId", idPartner);
              this.addCustomClarityTag(
                "systemUrl",
                systemUrl ?? this.getPartnerName()
              );
              window.parent.postMessage(
                {
                  name: "iframe.assine:vendas.hubId",
                  value:
                    esteiraConfiguracao?.hubId ??
                    "af8ad7e9-12ab-4535-b165-c28e84220fab",
                },
                "*"
              );
              return config;
            }
          }
        );
      } catch (error) {
        this.generalError(true);
      }
    },
    saveClient({ endereco, ...cliente }) {
      this.$store.dispatch("order/setAddress", {
        ...endereco,
        numero: endereco.numImovel,
      });
      this.$store.dispatch("order/setPersonalData", {
        ...cliente,
        retomada: true,
      });
    },
    addGTM(gtm, id) {
      const script = document.createElement("script");
      const code = `
      (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', '${gtm}');
      `;

      script.innerHTML = code;
      script.id = id;
      document.head.appendChild(script);
    },
    addCustomClarityTag(key, value) {
      window.clarity("set", key, value);
    },
    getPartnerName() {
      return window.location.host ?? "(not_set)";
    },
    addCss(css) {
      const linkCss = document.createElement("link");
      linkCss.href = css;
      linkCss.rel = "stylesheet";
      document.head.appendChild(linkCss);
    },
    addFavicon(favicon) {
      const linkFavicon = document.createElement("link");
      linkFavicon.href = favicon;
      linkFavicon.rel = "icon";
      linkFavicon.sizes = "16x16 32x32 48x48 64x64";
      document.head.appendChild(linkFavicon);
    },
    addLogo(logo) {
      this.$store.dispatch("ui/setPageLogo", logo);
    },
    addTitle(text) {
      const title = document.createElement("title");
      title.text = text;
      document.head.appendChild(title);
    },
    // addGTM(gtmCode) {
    //   const script = document.createElement("script");
    //   const gtm = gtmCode ?? process.env.VUE_APP_GTM;
    //   const code = `
    //   (function (w, d, s, l, i) {
    //   w[l] = w[l] || []; w[l].push({
    //     'gtm.start':
    //       new Date().getTime(), event: 'gtm.js'
    //   }); var f = d.getElementsByTagName(s)[0],
    //     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
    //       'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    // })(window, document, 'script', 'dataLayer', '${gtm}');
    //   `;

    //   script.innerHTML = code;
    //   script.id = "gtm-config";

    //   document.head.appendChild(script);
    // },
    saveParceiroConfig(config) {
      this.$store.dispatch("ui/setParceiroConfig", config);
    },
    async getCatalog({ url }) {
      // const res = await window.fetch(url);
      // return await res.json();
      return await axios.get(url);
    },
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-primary"
  >
    <a-loading />
  </div>
</template>
