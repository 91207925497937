<script>
import ALoading from "@components/atoms/a-loading.vue";

export default {
  name: "fixaLoading",
  components: {
    ALoading,
  },
};
</script>

<template>
  <a-loading />
</template>
