export default function parseCardsInfos(plans) {
  return plans.map(plan => ({
    hasBadge: plan.info_plano.flag !== null,
    isCombo: plan.info_plano.flag
      ? plan.info_plano.flag.filter(item => item !== "bestoffer")[0]
      : null,
    isBestOffer:
      plan.info_plano.flag !== null
        ? plan.info_plano.flag.filter(item => item === "bestoffer").length > 0
        : false,
    dados: plan.info_plano.dados,
    dadosSubtitle: plan.info_plano.dados_detalhe.map(item => ({
      url: plan.info_plano.url_img + item.img,
      // url: item.img.split(".")[0],
      name: item.title,
    }))[0],
    price: plan.valores_plano.valor_oferta,
    price_description: plan.valores_plano.valor_descricao
      ? plan.valores_plano.valor_descricao
      : undefined,
    services: {
      titulo: plan.info_plano.apps?.titulo ? plan.info_plano.apps?.titulo : "",
      detalhe: plan.info_plano.apps
        ? plan.info_plano.apps.detalhe_app.map(item => ({
            // url: store.getters["catalog/getUrlCDN"] + item.imagem,
            url: plan.info_plano.url_img + item.imagem,
            name: item.app,
          }))
        : [],
    },
    detalhes: plan.info_plano.detalhe.filter(plan => !plan.ver_mais),
    viewMoreTitle: plan.info_plano.detalhe.filter(plan => plan.ver_mais).length
      ? plan.info_plano.detalhe.filter(plan => plan.ver_mais)[0].titulo
      : "",
    viewMore: plan.info_plano.detalhe.filter(plan => plan.ver_mais).length
      ? plan.info_plano.detalhe.filter(plan => plan.ver_mais)[0].detalhe
        ? plan.info_plano.detalhe.filter(plan => plan.ver_mais)[0].detalhe
        : []
      : [],
    imgFeature:
      plan.info_plano.img_card && plan.info_plano.img_card[0].length
        ? plan.info_plano.url_img + plan.info_plano.img_card
        : null,
    disclaimer: plan.info_plano.texto_sidebar,
    sku: plan.info_plano.sku,
    ordem: plan.info_plano.ordem,
    view: plan.info_plano.view,
    style_card: {
      cor: plan.info_plano.style_card[0].cor,
      cor_primaria: plan.info_plano.style_card[0].cor_primaria,
      cor_secundaria: plan.info_plano.style_card[0].cor_secundaria,
      img: plan.info_plano.style_card[0].img,
      img_color: plan.info_plano.style_card[0].img_color,
    },
  }));
}
