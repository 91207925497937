export default function objFromUrlParam(url, paramsPattern = "utm") {
  const splitUrl = url.split("?");

  if (!splitUrl[1]) return {};
  const paramsArray = splitUrl[1].split("&");
  const filteredParams = paramsArray.filter(item =>
    item.includes(paramsPattern)
  );

  const result = filteredParams.reduce((acc, curr) => {
    const splitUtm = curr.split("=");
    acc[splitUtm[0]] = splitUtm[1];
    return acc;
  }, {});

  return result;
}
