<script>
export default {
  name: "ABreadcrumbItem",
  props: {
    hasPassed: {
      type: Boolean,
      default: () => false,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    isFirst: {
      type: Boolean,
      default: () => false,
    },
    isLast: {
      type: Boolean,
      default: () => false,
    },
    position: Number,
    name: String,
  },
};
</script>

<template lang="pug">
.a-breadcrumb-item(
  class="px-4 sm:px-3 flex flex-col items-center justify-center relative overflow-hidden"
  :class="{'has-passed': hasPassed, 'is-active': isActive, 'pl-0': isFirst, 'pr-0': isLast}"
  )
  .a-bread-circle(
    class="bg-cover w-5 h-5 sm:w-6 sm:h-6 border border-solid rounded-full text-xs leading-none flex items-center justify-center transition-all duration-1000 ease-in-out"
    :class="{'border-primary': hasPassed || isActive, 'border-light-300': !hasPassed && !isActive}")
    span( class="transition-all duration-1000 ease-in-out" :class="{'hidden': hasPassed, 'text-primary': isActive, 'text-light-300': !isActive && !hasPassed}") {{position}}
  .a-bread-title(
    class="pt-2 hidden sm:block transition-all duration-1000 ease-in-out"
    :class="{'text-dark-200': hasPassed, 'text-primary': isActive, 'text-light-300': !isActive && !hasPassed}"
    ) {{name}}
  .a-bread-indent-left(
    class="w-full h-px bg-light-300 rounded-full absolute top-1/2 sm:top-3 -left-1/2 transform  transition-all duration-1000 ease-in-out"
    :class="{'hidden': isFirst, '-translate-x-2 sm:-translate-x-5': isLast, '-translate-x-3 sm:-translate-x-5': !isLast, '-translate-x-4 sm:-translate-x-5': !isLast && !isFirst}")
  .a-bread-indent-right(
    class="w-full h-px bg-light-300 rounded-full absolute top-1/2 sm:top-3 left-1/2 transform  transition-all duration-1000 ease-in-out"
    :class="{'hidden': isLast, 'translate-x-2 sm:translate-x-5': isFirst, 'translate-x-3 sm:translate-x-5': !isFirst, 'translate-x-4 sm:translate-x-5': !isFirst && !isLast}")

</template>

<style lang="scss" scoped>
.a-breadcrumb-item {
  &.has-passed {
    .a-bread-circle {
      @apply bg-primary;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.40871 10.6634C7.21485 10.851 6.90705 10.851 6.71319 10.6634L4.37112 8.39622C4.16814 8.19973 4.16814 7.87421 4.37112 7.67772L4.91137 7.15474C5.10523 6.96708 5.41302 6.96708 5.60689 7.15474L6.71319 8.22563C6.90705 8.41329 7.21485 8.41329 7.40871 8.22562L10.3931 5.33664C10.587 5.14898 10.8948 5.14898 11.0886 5.33664L11.6289 5.85962C11.8319 6.05611 11.8319 6.38163 11.6289 6.57812L7.40871 10.6634Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position: center center;
    }
  }
  &.has-passed,
  &.is-active {
    .a-bread-indent-left,
    .a-bread-indent-right {
      @apply bg-primary;
    }
  }
}
</style>
