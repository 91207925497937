import isObject from "./is-object";
import toSnake from "./to-snake";
import isArray from "./is-array";

export default function keysToSnake(obj) {
  if (isObject(obj)) {
    const newObj = {};

    Object.keys(obj).forEach(
      key => (newObj[toSnake(key)] = keysToSnake(obj[key]))
    );

    return newObj;
  } else if (isArray(obj)) {
    return obj.map(i => keysToSnake(i));
  }
  return obj;
}
