function initTracking() {
  const queries = new URLSearchParams(document.location.search);
  // const systemUrl =
  //   process.env.NODE_ENV === "development"
  //     ? "hmg"
  //     : window.location.hostname.split(".")[0];
  // // Verifica a disponibilidade do Objeto Tacking na página
  if (typeof Tracking !== "undefined") {
    // eslint-disable-next-line no-undef
    let tracking = new Tracking(
      `${queries.get("company") ?? "app"}.indiquei.vc`
    );
    // console.log("tracking: ", tracking);
    return tracking;
  }
}

/**
 * Procedure de instalação do plugin
 *
 * @param Vue
 */
const install = function(Vue) {
  Vue.prototype.$tracking = Vue.tracking = initTracking();
};

export default { install };
