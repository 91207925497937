<script>
import IconLoading from "@assets/svg/icn-loading.svg?inline";

export default {
  name: "ALoading",
  components: {
    IconLoading,
  },
  props: {
    notTransparent: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    time: 0,
  }),
  mounted() {
    this.time = 0;
    setInterval(() => {
      this.time++;
    }, 1000);
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 text-light flex flex-col items-center justify-center z-50"
  >
    <div
      class="absolute top-0 bottom-0 left-0 right-0 bg-dark-200 opacity-75"
    ></div>
    <icon-loading class="text-loading w-10 h-10 animate-spin opacity-100" />
    <transition>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time <= 1">
        Carregando
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 2">
        Carregando.
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 3">
        Carregando..
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 4">
        Carregando...
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 5">
        Carregando....
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 6">
        Só mais um instante
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 7">
        Só mais um instante.
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 8">
        Só mais um instante..
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time === 9">
        Só mais um instante...
      </p>
      <p class="text-xl font-normal text-light mt-12 z-50" v-if="time > 9">
        Estamos quase lá...
      </p>
    </transition>
  </div>
</template>
