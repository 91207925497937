<script>
import oSidebar from "@components/organism/o-sidebar.vue";
import MinimalLayout from "@layouts/minimal.vue";
export default {
  components: { oSidebar, MinimalLayout },
  name: "MainLayout",
};
</script>

<template>
  <div
    class="box-border md:w-full md:max-h-full-fixed md:p-4 xl:p-8 md:h-screen md:flex md:flex-row-reverse"
  >
    <aside class="">
      <o-sidebar />
    </aside>
    <minimal-layout has-sidebar />
  </div>
</template>
