import router from "@router";
import {
  getBannerCampaignInConfigBySku,
  getBannerInCatalogBySku,
} from "@/utils/banner-methods";

import parseCardsInfos from "@utils/parse-cards-infos";
import keysToSnake from "../../utils/keys-to-snake";
import objFromUrlParam from "../../utils/obj-from-url-param";
import getParsedLink from "../../utils/get-parsed-link";

const Types = {
  CLEAR_DATA: "CLEAR_DATA",
  SET_CATALOG: "SET_CATALOG",
  SET_OFFER: "SET_OFFER",
  SET_CURRENT_PLAN: "SET_CURRENT_PLAN",
  SET_VALID_PLANS: "SET_VALID_PLANS",
};

export const state = {
  portfolio: {},
  selectedPlan: "",
  validPlans: [],
  oferta: {},
};

export const getters = {
  getAllPlans: state => [
    ...(state.portfolio.controle || []),
    ...(state.portfolio.pos || []),
    ...(state.portfolio.fixa || []),
  ],
  getPlanSku: state => state.selectedPlan,
  getSelectedPlan: (state, getters) => ({
    ...(getters.getAllPlans.filter(
      plano => plano.sku === state.selectedPlan
    )[0] ||
      getters.getAllPlans[0] ||
      {}),
  }),
  getMethodMessage: (_, getters) =>
    getters.getSelectedPlan.info_plano.texto_pagamento,
  getSharedLinks: (_, getters) =>
    getters.getSelectedPlan.info_plano.compartilhamento,
  getSegmento: (_, getters) => {
    const {
      info_plano: { tipo_fatura },
    } = getters.getSelectedPlan;
    if (tipo_fatura.toLowerCase() === "digital") {
      return "boleto";
    } else {
      return tipo_fatura.replace("ã", "a").toLowerCase() || "cartao";
    }
    // return getters.getSelectedPlan.info_plano;
  },
  tipoPlano: (_, getters) =>
    getters.getSelectedPlan.info_plano.tipo_plano.toLowerCase() || "controle",
  subTipoPlano: (_, getters) => {
    if (getters.getSelectedPlan.info_plano.sub_tipo_plano)
      return (
        getters.getSelectedPlan.info_plano.sub_tipo_plano.toLowerCase() || ""
      );
    else return "";
  },
  getCategory: (_, getters) =>
    `movel/plano/${getters.tipoPlano + getters.subTipoPlano}`,
  getBanner: (state, getters, rootState) => {
    const sku = state.selectedPlan;
    const campaigns = rootState.ui.parceiroConfig.banner.campanha;
    const catalogo = getters.getAllPlans;
    const existsOnConfig = campaigns
      ? getBannerCampaignInConfigBySku(sku, campaigns)
      : false;
    if (existsOnConfig) return existsOnConfig;
    if (Object.keys(catalogo).length > 0)
      return getBannerInCatalogBySku(sku, catalogo);
    return state.oferta;
  },
  getBannerLink: (state, getters, rootState, rootGetters) => {
    const bannerUrl = getters.getBanner.link;
    const rootUtms = keysToSnake(rootGetters.getQuery);
    delete rootUtms.dados_adicionais;
    const bannerUtms = objFromUrlParam(bannerUrl);

    return getParsedLink(bannerUtms, rootUtms, bannerUrl);
  },

  getValidPlans: (state, getters) =>
    state.validPlans.map(
      sku => getters.getAllPlans.filter(plan => plan.sku === sku)[0]
    ),
  // hasTl: (_, getters) => getters.getSelectedPlan.info_plano.idCrmTL,
  hasTl: (_, getters) => getters.getSelectedPlan.info_plano.hasTl || false,
  getSidebarInfos: (_, getters) =>
    parseCardsInfos([{ ...getters.getSelectedPlan }])[0],
  getCardsInfos: (_, getters) =>
    parseCardsInfos(
      getters.getAllPlans.filter(plan => plan.info_plano.view === "TRUE")
    ),
  getCardsViewMore: (_, getters) =>
    parseCardsInfos([{ ...getters.getSelectedPlan }])[0],
  getUrlContract: (_, getters) =>
    _.selectedPlan !== ""
      ? getters.getSelectedPlan.info_plano.url_contrato
      : "#",
  getUrlCDN: (_, getters) =>
    _.selectedPlan !== ""
      ? getters.getSelectedPlan.info_plano.url_img
      : "https://automatuslab.blob.core.windows.net/vivofluxoonline/VIVO/fibra/",
  getOperadora: (_, getters) =>
    getters.getSelectedPlan.info_plano.operadora.toLowerCase(),
  hasSkuVariant: (_, getters) => {
    const sku = getters.getSelectedPlan.info_plano.sku.substring(0, 7);
    return getters.getAllPlans.filter(plano => plano.sku.includes(sku));
  },
  getSkuVariant: (_, getters) => {
    const sku = getters.getSelectedPlan.info_plano.sku;
    const variants = getters.hasSkuVariant;
    return variants.find(plano => plano.sku !== sku);
  },
};
export const mutations = {
  [Types.SET_CATALOG]: (state, portfolio) => (state.portfolio = portfolio),
  [Types.SET_OFFER]: (state, oferta) => (state.oferta = oferta),
  [Types.SET_CURRENT_PLAN]: (state, selectedPlan) =>
    (state.selectedPlan = selectedPlan),
  [Types.SET_ELEGIBILIDADES]: (state, elegibilidades) =>
    (state.elegibilidades = elegibilidades),
  [Types.SET_TECNOLOGIA]: (state, tecnologia) =>
    (state.tecnologia = tecnologia),
  [Types.SET_VALID_PLANS]: (state, skuArr = []) => (state.validPlans = skuArr),
  [Types.CLEAR_DATA]: state => {
    state.validPlans = [];
    state.selectedPlan = "";
    state.portfolio = {};
    state.whitelist = [];
  },
};
export const actions = {
  clearData: ({ commit }, sku) => commit(Types.CLEAR_DATA, sku),
  setCatalog({ commit }, catalog) {
    commit(Types.SET_CATALOG, catalog.portfolio);
  },
  setOffer({ commit }, oferta) {
    commit(Types.SET_OFFER, oferta);
  },
  setSelectedPlan({ commit, getters }, sku = "OFBL001DN") {
    // const currentPlan = {
    //   ...(getters.getAllPlans.filter(plano => plano.sku === sku)[0] ||
    //     getters.getAllPlans[0] ||
    //     {}),
    // };
    commit(Types.SET_CURRENT_PLAN, sku);
    return getters;
  },
  setValidPlans({ commit }, skuArr = []) {
    commit(Types.SET_VALID_PLANS, skuArr);
  },
  async setCurrentPlanAndFetch(
    { commit, rootGetters, dispatch },
    { sku, toggle }
  ) {
    const payload = {
      identificadorPedido: rootGetters["order/getOrderId"],
      produto: {
        fixa: {
          tipoProduto: "FiXA",
          sku: sku,
          modalidade: "NovaLinha",
        },
      },
    };
    await dispatch(
      "api",
      { entity: "api", action: "AtualizarProduto", payload },
      { root: true }
    );
    commit(Types.SET_CURRENT_PLAN, sku);
    if (toggle) await dispatch("ui/toggleMoreDetails", null, { root: true });
    await router.push("/dados-pessoais");
  },
};
