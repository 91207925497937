<script>
export default {
  name: "AImage",
  props: {
    paths: Array[Object],
    noTitle: {
      type: Boolean,
      default: () => false,
    },
    classList: String,
  },
};
</script>

<template lang="pug">
span
  template(v-for="(path, idx) in paths")
    slot(:path="{...path, idx, size: paths.length}")
      img(v-if="noTitle" :src="path.url" :class="classList")
      img(v-else :src="path.url" :alt="path.name" :class="classList")
    
</template>
