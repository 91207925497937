<script>
import AText from "@components/atoms/a-text";
import APrice from "@components/atoms/a-price.vue";
import AImage from "@components/atoms/a-image.vue";

import MFeatureItem from "@components/molecules/m-feature-item";
import MSharedIcons from "@components/molecules/m-shared-icons";

import IconBase from "@components/icons/_icon-base";
import IconCart from "@components/icons/_icon-cart";
import IconChevron from "@components/icons/_icon-chevron";
import IconCheck from "@components/icons/_icon-check";
import { mapGetters } from "vuex";

export default {
  name: "OSidebar",
  components: {
    AText,
    APrice,
    AImage,
    MFeatureItem,
    MSharedIcons,
    IconBase,
    IconCart,
    IconChevron,
    IconCheck,
  },
  data: () => ({
    isOpen: false,
    sidebarData: {
      features: [
        {
          titulo: "Fixo Ilimitado Brasilll",
          detalhe: null,
        },
        {
          titulo: "Wi-Fi grátis",
          detalhe: "Download até 50Mbps e Upload até 25Mbps",
        },
        {
          titulo: "Serviços digitais*",
          detalhe: [
            {
              app: "Discovery Kids!",
              imagem: "app-dkids.svg",
            },
            {
              app: "Watch ESPN",
              imagem: "app-watchespn.svg",
            },
            {
              app: "Operadora Protege",
              imagem: "app-operadoraprotege.svg",
            },
            {
              app: "CN Já!",
              imagem: "app-cnja.svg",
            },
            {
              app: "Esporte interativo",
              imagem: "app-ei.svg",
            },
            {
              app: "Operadora Games 4U",
              imagem: "app-operadorag4u.svg",
            },
          ],
        },
        {
          titulo: "TV Full HD",
          detalhe: "Com 103 canais, sendo 89 em HD",
        },
        {
          titulo: "Netflix",
          detalhe: "Assinatura padrão | 2 telas simultâneas em HD",
        },
      ],
    },
  }),
  methods: {
    toggle() {
      this.isOpen = this.isOpen ? false : true;
      if (window && window.scrollTo) window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters({
      plano: "catalog/getSidebarInfos",
      infoParabens: "order/getInfoParabens",
      getModality: "order/getModality",
    }),
    isParabens() {
      return this.$route.name === "Parabens";
    },
    features() {
      return !this.isParabens
        ? [...this.plano.detalhes, ...this.plano.viewMore]
        : [...this.infoParabens];
    },
  },
};
</script>

<template lang="pug">
.o-sidebar( 
  class="fixed top-0 z-20 flex flex-col justify-between w-screen overflow-hidden transition-all md:relative bg-primary shadow-glow text-light duration-600 ease-in-out-quint"
  :class="{'h-16 rounded-bl-1/4 rounded-br-1/4 pt-2-1/2': !isOpen, 'h-screen max-h-full-fixed rounded-none pt-5': isOpen, 'md:h-screen md:max-h-full md:max-w-xs md:rounded-lg md:pt-5': !isOpen || isOpen}"
  :style="{ 'background-color': plano.style_card.cor_primaria }"
  )
  //-  Icon Toggle
  .o-sidebar--top-wrapper( class="")
    .o-sidebar--icon-toggle( 
      class="absolute top-0 right-0 z-30 block p-4 transition-transform duration-300 ease-in-out delay-500 cursor-pointer"
      :class="{'transform rotate-0': !isOpen, 'transform rotate-180': isOpen, 'md:hidden' : !isOpen || isOpen}"
      @click="toggle")
      icon-base(:size='8')
        icon-chevron
    //- Title da sidebar
    .o-sidebar--title(
      class="flex items-center px-4 transition-all duration-300 ease-in-out md:px-6")
      icon-base
        icon-cart(v-if="!isParabens")
        icon-check(v-else)
      a-text(tag='p' class="pl-1 text-xs font-medium text-light" ignore-style)
        | {{ !isParabens ?  "Seu plano" : "Plano Contratado"}}

    //- Plano + preço (apenas no mobile)
    //- div(class="w-16 mx-6 mt-4")
    //-   a-image(:paths="[{ url: plano.style_card.img }]")
    .o-sidebar--plan-price(
      class="flex px-4 transition-all duration-300 ease-in-out md:px-6 text-secondary-0 md:flex-col md:items-start"
      :class="{'mt-1 items-center': !isOpen, 'flex-col mt-4 items-start': isOpen, 'md:mt-4': !isOpen || isOpen}"
      :style="{ color: plano.style_card.cor_secundaria }"
    )
      div(
        class="w-8 mr-4 md:w-16"
        :class="{'w-16': isOpen}"
        )
        a-image(:paths="[{ url: plano.style_card.img }]")
      a-text(
        tag='p'
        class="font-bold leading-none transition-all duration-300 ease-in-out md:mt-2"
        :class="{'text-2xl': !isOpen && !isParabens, 'text-4xl': isOpen && !isParabens, 'md:text-4xl': !isParabens, 'text-xl': !isOpen && isParabens, 'text-2xl': isOpen && isParabens, 'md:text-2xl': isParabens}"
        ignore-style) {{plano.dados}}
      a-price( :price-value="plano.price" v-if="!isParabens")
        template( slot-scope="{price}")
          a-text(
            tag="p"
            class="ml-5 text-sm font-medium leading-none transition-all duration-300 ease-in-out"
            :class="{'transform translate-x-0 opacity-1': !isOpen, 'transform -translate-x-1/2 opacity-0': isOpen, 'md:hidden': !isOpen || isOpen}"
            ignore-style)  {{ price }}

    .o-sidebar--inner-wrapper(
      class="px-4 mt-2 overflow-y-auto transition-opacity md:px-6 duration-600 ease-in-out-quint"
      :class="{'opacity-0': !isOpen, 'opacity-100': isOpen, 'opacity-100': !isOpen || isOpen }"
      )
      template(v-if="isParabens")
        a-text(
          tag="p" class="text-base text-secondary-0" ignore-style
          :style="{ color: plano.style_card.cor_secundaria }"
        ) {{plano.dadosSubtitle.name}}
        a-price( :price-value="plano.price")
          template( slot-scope="{price}")
            a-text(
              tag="p"
              class="mt-2 mb-10 text-base font-medium leading-none md:mb-6 text-light"
              ignore-style)  {{ price }}

      m-feature-item(
        v-for="(feature, idx) in features"
        :key="idx"
        :feature="feature")

      a-text(tag="p" class="mb-4 text-xs font-medium leading-none opacity-50 text-light-200") {{plano.disclaimer}}

  .o-sidebar--footer(
    class="flex items-center justify-between px-4 transition-all duration-500 min-h-14 md:p-6 ease-in-out-quint"
    :class="{'transform translate-y-full': !isOpen, 'delay-700 transform translate-y-0': isOpen, 'md:translate-y-0': !isOpen || isOpen, 'bg-primary-200' : !isParabens}"
    :style="{ 'background-color': plano.style_card.cor_primaria }"
  )
    
    //- m-shared-icons(v-if="isParabens")
    //- template(v-else)
    template(v-if="!isParabens")
      a-text(
        tag='p'
        class="text-sm font-bold leading-none transition-all duration-300 ease-in-out"
        ignore-style) Tudo isso por
      a-price( :price-value="plano.price")
        template( slot-scope="{price}")
          a-text(
            tag="p"
            class="text-xl font-medium leading-none text-secondary-0 "
            ignore-style
            :style="{ color: plano.style_card.cor_secundaria }"
            )  {{ price }}


</template>

<style lang="scss" scoped>
.o-sidebar--top-wrapper {
  height: calc(100% - 3.5rem);
}
.o-sidebar--inner-wrapper {
  max-height: calc(100% - 4.8rem);
}
</style>
