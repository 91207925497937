import pick from "lodash/pick";
export default function getUtmsAndAdditionalData(query) {
  const dadosAdicionais = pick(
    query,
    Object.keys(query).filter(item => !item.includes("utm"))
  );
  const utm = pick(
    query,
    Object.keys(query).filter(item => item.includes("utm"))
  );
  utm.dadosAdicionais = dadosAdicionais;

  return utm;
}
