import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import modules from "./modules";
import rootStore from "./rootStore";

Vue.use(Vuex);

const vuexSessionStorage = new VuexPersist({
  key: process.env.VUE_APP_SESSION_NAME,
  storage: window.sessionStorage,
});
const plugins = [];
plugins.push(vuexSessionStorage.plugin);

export default new Vuex.Store({
  ...rootStore,
  modules,
  plugins,
});
