export default function getParsedLink(
  obj1 = {},
  obj2 = {},
  baseUrl = "http://localhost:8080/fixa/?teste=true"
) {
  const concatValues = Object.keys(obj2).reduce(
    (acc, keyObj2) => {
      const keyMatch = Object.keys(obj1).find(keyObj1 => keyObj2 === keyObj1);
      if (keyMatch) {
        acc[keyObj2] = `${obj2[keyObj2]}.${obj1[keyObj2]}`;
      } else {
        acc[keyObj2] = obj2[keyObj2];
      }
      return acc;
    },
    { ...obj1 }
  );

  return (
    baseUrl.split(/(\/\?)|(\?)/g)[0] +
    "/?" +
    Object.entries(concatValues)
      .map(([key, value]) => {
        return `${key}=${value}`.substr(0, 100);
      })
      .join("&")
  );
}
