<script>
// import IconBase from "@components/icons/_icon-base.vue";
// import IconLogo from "@components/icons/_icon-logo.vue";
import MBreadcrumb from "@components/molecules/m-breadcrumb.vue";
import AImage from "../atoms/a-image.vue";

export default {
  name: "OHeader",
  components: {
    // IconBase,
    // IconLogo,
    MBreadcrumb,
    AImage,
  },
  props: {
    noBreadcrumb: Boolean,
  },
  computed: {
    logoPath() {
      return this.$store.getters["ui/getPageLogo"];
    },
  },
};
</script>

<template lang="pug">
  header( class="flex justify-between items-center px-4")
    //- IconBase(:size="21.425" class="text-primary")
    //-   IconLogo
    AImage(:paths="[{ url: this.logoPath, name: 'logo' }]" classList="h-22 w-22")
    MBreadcrumb(v-if="!noBreadcrumb")  
</template>
