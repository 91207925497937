<script>
import ABreadcrumbItem from "@components/atoms/a-breadcrumb-item.vue";
export default {
  name: "MBreadcrumb",
  components: {
    ABreadcrumbItem,
  },
  computed: {
    positions() {
      return this.$store.getters["breadcrumb/getPositions"];
    },
    currentPosition() {
      return this.$store.getters["breadcrumb/getCurrentPositions"];
    },
  },
};
</script>

<template lang="pug">
  .m-breadcrumb( class="flex")
    template(v-for="position in positions")
      ABreadcrumbItem(
        :name="position.name"
        :position="position.position"
        :is-active="position.position === currentPosition"
        :has-passed="position.position < currentPosition"
        :is-first="position.position === 1"
        :is-last="position.position === positions.length"
        )
</template>
