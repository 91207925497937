import removeAccentuation from "./remove-accentuation";
import removeSpecialChars from "./remove-special-chars";
import toKebabCase from "./to-kebab-case";

export default function stringSanitize(string) {
  //
  let result = removeAccentuation(string);
  //
  result = removeSpecialChars(result, true);
  result = toKebabCase(result);
  //
  return result;
}
