var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"flex-grow md:flex md:flex-col md:max-h-full-fixed",class:{
    'md:h-screen': !_vm.hasSidebar,
    'md:h-full pt-14 md:p-0': _vm.hasSidebar,
  }},[_c('o-header',{class:{
      'md:-mt-4': !_vm.isMinimal,
      'md:max-w-5xl w-full md:mx-auto': 1,
    },attrs:{"no-breadcrumb":_vm.noBreadcrumb}}),_c('main',{staticClass:"flex-grow overflow-y-auto box-border",class:{
      'px-4': !_vm.noPadding,
      'md:max-w-5xl w-full md:mx-auto': 1,
    }},[_c('clean-layout')],1),(!_vm.noFooter)?_c('o-footer',{staticClass:"py-4 box-border px-4 md:max-w-5xl w-full md:mx-auto",class:{ 'md:pb-0': _vm.hasSidebar }}):_vm._e(),(_vm.hasCarousel)?_c('o-more-details'):_vm._e(),(_vm.isAPICallInProgress)?_c('a-loading'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }