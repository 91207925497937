import Events from "./events";
import stringSanitize from "@utils/string-sanitize";

/**
 * Procedure de instalação do plugin
 *
 * @param Vue
 * @param options
 */
const install = function(Vue, options = {}) {
  options = { ...options };

  if (options.vueRouter) RouterGuard(Vue, options);

  Vue.prototype.$gtm = Vue.gtm = new Events();
};

/**
 * Router guard para disparo automático de pageView
 *
 * @param Vue
 * @param vueRouter
 * @param {string[]} ignoredViews
 */
const RouterGuard = function(
  Vue,
  { vueRouter, ignoredViews, trackOnNextTick }
) {
  if (ignoredViews) ignoredViews = ignoredViews.map(view => view.toLowerCase());

  vueRouter.afterEach(to => {
    if (
      !to.name ||
      (ignoredViews.length &&
        ignoredViews.indexOf(to.name.toLowerCase()) !== -1)
    ) {
      return;
    }

    const name = to.meta.gtm || stringSanitize(to.name);
    const baseUrl = vueRouter.options.base || "";
    if (trackOnNextTick) {
      Vue.nextTick(() => {
        Vue.gtm.trackView(name, `${baseUrl}${to.fullPath}`);
      });
    } else {
      Vue.gtm.trackView(name, `${baseUrl}${to.fullPath}`);
    }
  });

  return ignoredViews;
};

export default { install };
