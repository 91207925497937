<template>
  <div>
    <div id="app" class="font-atual">
      <transition>
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  mounted() {
    this.orchestror();
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: "isAPICallInProgress",
      parceiroConfig: "ui/getParceiroConfig",
    }),
  },
  methods: {
    addCss(css) {
      const linkCss = document.createElement("link");
      linkCss.href = css;
      linkCss.rel = "stylesheet";
      document.head.appendChild(linkCss);
    },
    addFavicon(favicon) {
      const linkFavicon = document.createElement("link");
      linkFavicon.href = favicon;
      linkFavicon.rel = "icon";
      linkFavicon.sizes = "16x16 32x32 48x48 64x64";
      document.head.appendChild(linkFavicon);
    },
    addCustomClarityTag(key, value) {
      window.clarity("set", key, value);
    },
    getPartnerName() {
      return window.location.host ?? "(not_set)";
    },
    addLogo(logo) {
      this.$store.dispatch("ui/setPageLogo", logo);
    },
    addTitle(text) {
      const title = document.createElement("title");
      title.text = text;
      document.head.appendChild(title);
    },
    async orchestror() {
      if (this.parceiroConfig) {
        const config = this.parceiroConfig;
        const favicon = config.favicon;
        const logo = config.logo;
        const title = config.title;
        const css = config.css;
        const idPartner = config.idParceiro;

        this.addTitle(title);
        this.addLogo(logo);
        this.addCss(css);
        this.addFavicon(favicon);
        this.addCustomClarityTag("parceiroId", idPartner);
        this.addCustomClarityTag("systemUrl", this.getPartnerName());
      }
      return;
    },
  },
};
</script>
