const Types = {
  NEXT_STEP: "NEXT_STEP",
  TOGGLE_MOREDETAILS: "TOGGLE_MOREDETAILS",
  SET_PAGE_LOGO: "SET_PAGE_LOGO",
  SET_PARCEIRO_CONFIG: "SET_PARCEIRO_CONFIG",
  SET_GENERAL_ERROR: "SET_GENERAL_ERROR",
  SET_MESSAGE_ERROR: "SET_MESSAGE_ERROR",
  SET_AUTOFETCH_STATUS: "SET_AUTOFETCH_STATUS",
  SET_ORDER_STATUS: "SET_ORDER_STATUS",
};

export const state = {
  breadcrumb: {
    steps: [1, 2, 3, 4],
    currentStep: 1,
  },
  autoFetch: false,
  moreDetails: {
    isShowing: false,
  },
  pageLogo:
    "https://automatuslab.blob.core.windows.net/macro/AssineAqui/Parceiros/Parceiro_AssineFibra/Logo.svg",
  parceiroConfig: null,
  isGeneralError: false,
  messageError: "",
  completedOrder: false,
};

export const getters = {
  steps: state => state.breadcrumb.steps,
  currentStep: state => state.breadcrumb.currentStep,
  isShowingMoreDetails: state => state.moreDetails.isShowing,
  getPageLogo: state => state.pageLogo,
  getParceiroConfig: state => state.parceiroConfig,
  getIsGeneralError: state => state.isGeneralError,
  getMessageError: state => state.messageError,
  getAutoFetchStatus: state => state.autoFetch,
  getOrderStatus: state => state.completedOrder,
  getTemplateParabens: state =>
    state.parceiroConfig.template
      ? state.parceiroConfig.template.parabens
        ? state.parceiroConfig.template.parabens
        : false
      : false,
};

export const mutations = {
  [Types.NEXT_STEP]: (state, newStep) =>
    (state.breadcrumb.currentStep = newStep),
  [Types.TOGGLE_MOREDETAILS]: (state, payload) =>
    (state.moreDetails.isShowing = payload),
  [Types.SET_PAGE_LOGO]: (state, path) => (state.pageLogo = path),
  [Types.SET_PARCEIRO_CONFIG]: (state, obj) => (state.parceiroConfig = obj),
  [Types.SET_GENERAL_ERROR]: (state, value) => (state.isGeneralError = value),
  [Types.SET_MESSAGE_ERROR]: (state, message) => (state.messageError = message),
  [Types.SET_AUTOFETCH_STATUS]: (state, status) => (state.autoFetch = status),
  [Types.SET_ORDER_STATUS]: (state, status) => (state.completedOrder = status),
};

export const actions = {
  setNewStep: ({ commit }, newStep) => commit(Types.NEXT_STEP, newStep),
  toggleMoreDetails: ({ commit, getters, dispatch }, sku) => {
    if (sku) dispatch("catalog/setSelectedPlan", sku, { root: true });
    commit(Types.TOGGLE_MOREDETAILS, !getters.isShowingMoreDetails);
  },
  setPageLogo: ({ commit }, pageLogo) => commit(Types.SET_PAGE_LOGO, pageLogo),
  setParceiroConfig: ({ commit }, parceiroConfig) =>
    commit(Types.SET_PARCEIRO_CONFIG, parceiroConfig),
  setIsGeneralError: ({ commit }, value) =>
    commit(Types.SET_GENERAL_ERROR, value),
  setMessageError: ({ commit }, message) =>
    commit(Types.SET_MESSAGE_ERROR, message),
  setAutoFetchStatus: ({ commit }, status) =>
    commit(Types.SET_AUTOFETCH_STATUS, status),
  setOrderStatus: ({ commit }, status) =>
    commit(Types.SET_ORDER_STATUS, status),
};
