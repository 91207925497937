const Types = {
  SET_POSITION: "SET_POSITION",
};

export const state = {
  positions: [
    { position: 4, name: "Pagamento", hasPassed: false, isActive: false },
    { position: 2, name: "Seus dados", hasPassed: false, isActive: false },
    { position: 1, name: "Viabilidade", hasPassed: false, isActive: false },
    { position: 3, name: "Instalação", hasPassed: false, isActive: false },
  ],
  currentPosition: 1,
};

export const getters = {
  getPositions: state =>
    state.positions.sort((a, b) =>
      a.position > b.position ? 1 : b.position > a.position ? -1 : 0
    ),
  getCurrentPositions: state => state.currentPosition,
};

export const mutations = {
  [Types.SET_POSITION]: (state, newPosition) =>
    (state.currentPosition = newPosition),
};

export const actions = {
  setCurrentPosition: ({ commit }, newPosition) =>
    commit(Types.SET_POSITION, newPosition),
};
