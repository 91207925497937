const Types = {
  START_SUBMIT: "START_SUBMIT",
  PUSH_FORM: "PUSH_FORM",
};

export const state = {
  formSubmiteds: [],
  isFormSubmit: false,
};

export const getters = {
  getFormsSubmiteds: state => state.formSubmiteds,
  isFormSubmit: state => state.isFormSubmit,
};

export const mutations = {
  [Types.START_SUBMIT]: state => (state.isFormSubmit = !state.isFormSubmit),
  [Types.PUSH_FORM]: (state, form) =>
    (state.formSubmiteds = [...state.formSubmiteds, ...form]),
};

export const actions = {
  startSubmit: ({ commit }, form) => {
    commit(Types.PUSH_FORM, [form]);
    commit(Types.START_SUBMIT);
  },
};
