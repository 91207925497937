export default function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    // component: import(/* webpackChunkName: "fixa__dados-pessoais" */ AsyncView),
    component: AsyncView,
    loading: require("@pages/_loading.vue").default,
    delay: 100,
    error: require("@pages/_timeout.vue").default,
    timeout: 60000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children);
    },
  });
}
