export default async function fetch(
  fetchApi,
  entity,
  action,
  payload,
  callbackFunc = () => {}
) {
  try {
    if (!payload) throw new Error("parametro 'payload' não pode ser vazio!");
    if (!entity) throw new Error("parametro 'entity' não pode ser vazio!");
    if (!action) throw new Error("parametro 'action' não pode ser vazio!");

    const { data } = await fetchApi({
      entity,
      action,
      payload,
    });
    callbackFunc({ ...data });
    return { retorno: { ...data }, error: false };
  } catch (err) {
    callbackFunc({ retorno: err, error: true });
    return { retorno: { ...err }, error: true };
    // throw new Error(err);
  }
}
