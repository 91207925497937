<script>
export default {
  name: "ATooltip",
  props: {
    text: {
      type: String,
      required: true,
      default: "tooltip",
    },
    position: {
      type: String,
      validator: value =>
        [
          "top-left",
          "top-center",
          "top-right",
          "center-right",
          "bottom-right",
          "bottom-center",
          "bottom-left",
          "center-left",
        ].includes(value),
    },
  },
  data: () => ({
    isShowing: true,
  }),
  methods: {
    toggleShow(event, value = null) {
      if (value !== null) {
        this.isShowing = value;
      } else {
        this.isShowing = !this.isShowing;
      }
    },
  },
};
</script>

<template>
  <span
    ref="tooltip"
    @click="toggleShow"
    @mouseover="toggleShow($event, true)"
    @mouseleave="toggleShow($event, false)"
    :data-tooltip="text"
    class="a-tooltip"
    :class="position"
  >
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.a-tooltip {
  @apply relative;
  @apply inline-block;
  &:before {
    @apply shadow-md;
  }
  &:before,
  &after {
    @apply z-10;
  }
  &.top-left:before,
  &.top-left:after,
  &:before,
  &:after {
    --scale: 0;
    --opacity: 0;
    --arrow-size: 0.5rem;
    --translate-y: -100%;
    --translate-x: calc(-100% + var(--arrow-size) * 2);
    --top: -0.3rem;
    --left: 50%;
    --bottom: inherit;
    --right: inherit;
    --bg-color: theme("colors.dark.300");
    --arrow-top-color: var(--bg-color);
    --arrow-bottom-color: transparent;
    --arrow-left-color: transparent;
    --arrow-right-color: transparent;
    --origin: bottom right;
    --box-shadow: ;
    @apply absolute text-base leading-normal text-left;
    bottom: var(--bottom, inherit);
    right: var(--right, inherit);
    top: var(--top);
    left: var(--left);
    transform: translateX(var(--translate-x, 0))
      translateY(var(--translate-y, 0)) scale(var(--scale));
    transform-origin: var(--origin);
    transition: 150ms all;
    opacity: var(--opacity);
    @media (min-width: theme("screens.sm")) {
      font-size: 0.875rem;
    }
  }
  &.top-left:before,
  &:before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    border-radius: 4px;
    color: theme("colors.light.default");
    background: var(--bg-color);
    padding: 0.5rem 1rem;
    width: max-content;
    max-width: 13rem;
  }
  &.top-left:after,
  &:after {
    --translate-y: calc(-1 * var(--arrow-size));
    --translate-x: -50%;
    --origin: top right;
    content: "";
    border: var(--arrow-size) solid transparent;
    border-top-color: var(--arrow-top-color);
    border-bottom-color: var(--arrow-bottom-color);
    border-left-color: var(--arrow-left-color);
    border-right-color: var(--arrow-right-color);
    transform-origin: var(--origin);
  }
  &:hover::before,
  &:hover::after {
    --opacity: 01;
    --scale: 1;
  }
  &.bottom-right {
    &:before,
    &:after {
      --translate-y: 100%;
      --top: 0;
      --left: 50%;
      --bottom: -0.25rem;
      --right: inherit;
      --arrow-top-color: transparent;
      --arrow-bottom-color: var(--bg-color);
      --arrow-left-color: transparent;
      --arrow-right-color: transparent;
      --origin: bottom right;
    }
    &:before {
      --translate-y: calc(100% + var(--arrow-size));
    }
    &:after {
      --translate-y: calc(1 * var(--arrow-size));
      --origin: top right;
    }
  }
  &.center-right {
    &:before,
    &:after {
      --top: 50%;
      --left: inherit;
      --bottom: inherit;
      --right: -0.29rem;
      --arrow-top-color: transparent;
      --arrow-bottom-color: transparent;
      --arrow-left-color: transparent;
      --arrow-right-color: var(--bg-color);
      --origin: center left;
    }
    &:before {
      --translate-y: -50%;
      --translate-x: calc(100% + var(--arrow-size));
      --right: -0.25rem;
    }
    &:after {
      --translate-y: -50%;
      --translate-x: calc(1 * var(--arrow-size));
      --origin: center right;
    }
  }
  &.center-left {
    &:before,
    &:after {
      --top: 50%;
      --left: -0.29rem;
      --bottom: inherit;
      --right: inherit;
      --arrow-top-color: transparent;
      --arrow-bottom-color: transparent;
      --arrow-left-color: var(--bg-color);
      --arrow-right-color: transparent;
      --origin: center right;
    }
    &:before {
      --translate-y: -50%;
      --translate-x: calc(-100% - var(--arrow-size));
      --left: -0.25rem;
    }
    &:after {
      --translate-y: -50%;
      --translate-x: calc(-1 * var(--arrow-size));
      --origin: center left;
    }
  }
  &.top-right {
    &:before,
    &:after {
      --top: -0.25rem;
      --left: inherit;
      --bottom: inherit;
      --right: 50%;
      --arrow-top-color: var(--bg-color);
      --arrow-bottom-color: transparent;
      --arrow-left-color: transparent;
      --arrow-right-color: transparent;
      --origin: bottom left;
    }
    &:before {
      --translate-x: calc(100% - var(--arrow-size) * 2);
      --translate-y: calc(-100% - var(--arrow-size));
    }
    &:after {
      --translate-x: 50%;
      --translate-y: calc(-1 * var(--arrow-size));
      --origin: top left;
    }
  }
  &.top-center {
    &:before,
    &:after {
      --top: -0.3rem;
      --left: 50%;
      --bottom: inherit;
      --right: inherit;
      --arrow-top-color: var(--bg-color);
      --arrow-bottom-color: transparent;
      --arrow-left-color: transparent;
      --arrow-right-color: transparent;
      --origin: bottom center;
    }
    &:before {
      --translate-x: -50%;
      --translate-y: calc(-100% - var(--arrow-size));
    }
    &:after {
      --translate-y: calc(-1 * var(--arrow-size));
      --origin: top center;
    }
  }
  &.bottom-center {
    &:before,
    &:after {
      --top: inherit;
      --left: 50%;
      --bottom: -0.25rem;
      --right: inherit;
      --arrow-top-color: transparent;
      --arrow-bottom-color: var(--bg-color);
      --arrow-left-color: transparent;
      --arrow-right-color: transparent;
      --origin: top center;
    }
    &:before {
      --translate-x: -50%;
      --translate-y: calc(100% + var(--arrow-size));
    }
    &:after {
      --translate-y: calc(1 * var(--arrow-size));
      --origin: bottom center;
    }
  }
  &.bottom-right {
    &:before,
    &:after {
      --top: inherit;
      --left: inherit;
      --bottom: -0.25rem;
      --right: 50%;
      --arrow-top-color: transparent;
      --arrow-bottom-color: var(--bg-color);
      --arrow-left-color: transparent;
      --arrow-right-color: transparent;
      --origin: top left;
    }
    &:before {
      --translate-x: calc(100% - var(--arrow-size) * 2);
      --translate-y: calc(100% + var(--arrow-size));
    }
    &:after {
      --translate-x: 50%;
      --translate-y: calc(1 * var(--arrow-size));
      --origin: bottom left;
    }
  }
  &.bottom-left {
    &:before,
    &:after {
      --top: inherit;
      --left: 50%;
      --bottom: -0.25rem;
      --right: inherit;
      --arrow-top-color: transparent;
      --arrow-bottom-color: var(--bg-color);
      --arrow-left-color: transparent;
      --arrow-right-color: transparent;
      --origin: top right;
    }
    &:before {
      --translate-x: calc(-100% + var(--arrow-size) * 2);
      --translate-y: calc(100% + var(--arrow-size));
    }
    &:after {
      --translate-x: -50%;
      --translate-y: calc(1 * var(--arrow-size));
      --origin: bottom right;
    }
  }
}
/* .a-tooltip::after,
.a-tooltip::before {
  content
  @apply absolute -top-1;
} */
</style>
