export const getBannerCampaignInConfigBySku = (sku, campaigns) =>
  campaigns.reduce(
    (acc, curr) =>
      curr.sku.filter(currSku => currSku === sku).length ? { ...curr } : acc,
    false
  ) ||
  campaigns.reduce((acc, curr) => (!curr.sku.length ? curr : acc), false) ||
  false;

export const getBannerInCatalogBySku = (sku, catalog) => {
  const filter = catalog.filter(item => item.sku === sku);
  return filter.length ? filter[0].info_plano.banner : null;
};
