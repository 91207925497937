const Types = {
  CLEAR_PERSONAL_DATA: "CLEAR_PERSONAL_DATA",
  SET_ORDER_ID: "SET_ORDER_ID",
  SET_ORDER_PERSONAL_DATA: "SET_ORDER_PERSONAL_DATA",
  SET_ADDRESS: "SET_ADDRESS",
  SET_STATUS_ADDRESS: "SET_STATUS_ADDRESS",
  SET_EXPIRATION_DAY: "SET_EXPIRATION_DAY",
  SET_BILL_METHOD: "SET_BILL_METHOD",
  SET_BILL_METHOD__DEBITO: "SET_BILL_METHOD__DEBITO",
  SET_BILL_METHOD__BOLETO: "SET_BILL_METHOD__BOLETO",
  SET_MODALITY: "SET_MODALITY",
  SET_PRODUCT: "SET_PRODUCT",
  SET_ACTIVATED: "SET_ACTIVATED",
  SET_CONSULT_STATUS: "SET_CONSULT_STATUS",
  SET_CONFIG_BILL_INFO: "SET_CONFIG_BILL_INFO",
};

export const state = {
  identificadorPedido: "",
  lead: {},
  endereco: {
    cep: "",
    // logradouro: "Rua Visconde de Itamarati",
    numero: "",
    bairro: "",
    uf: "",
    localidade: "",
  },
  diaVencimento: "07",
  formaPagamento: "Débito automático",
  modalidade: "Migracao",
  produto: {},
  activated: false,
  consultStatus: false,
};

export const getters = {
  getOrderId: state => state.identificadorPedido,
  getOrder: state => ({ ...state }),
  getAddress: state => state.endereco,
  getLead: state => state.lead,
  getLeadTelefone: state => state.lead.telefone || "",
  getLeadCpf: state => state.lead.cpf || "",
  getLeadEmail: state => state.lead.email || "",
  getLeadCep: state => state.lead.cep || "",
  getLeadName: state => state.lead.nome || "",
  getLeadNameMother: state => state.lead.nomeMae || "",
  getVencimento: state => state.diaVencimento,
  getBillMethod: state => state.formaPagamento,
  getModality: state => state.lead.modalidade,
  getInfoParabens: state => [
    { titulo: "Nome", descricao: state.lead.nome },
    { titulo: "CPF", descricao: state.lead.cpf },
    {
      titulo: "Sua Fatura",
      descricao: `${
        state.lead.fatura.tipoFatura.toLowerCase() === "impressa"
          ? "Via e-mail"
          : "Débito automático"
      } <br/>
                ${
                  state.lead.fatura.diaVencimento !== "0"
                    ? `Vencimento no dia ${state.lead.fatura.diaVencimento} de cada mês`
                    : ""
                }`,
    },
  ],
  getDiasVencimento: (state, getters, rootState, rootGetters) => {
    const operadora = rootGetters["catalog/getOperadora"];
    return state.billConfig[operadora].diaVencimento;
  },
  getListBank: (state, getters, rootState, rootGetters) => {
    const operadora = rootGetters["catalog/getOperadora"];
    const listBank = state.billConfig[operadora].bancosDA;

    const newListBank = listBank.map(bank => ({
      value: bank.codigo,
      text: bank.nome,
    }));

    return newListBank;
  },
  hasRG: (state, getters, rootState, rootGetters) => {
    const operadora = rootGetters["catalog/getOperadora"];
    return state.billConfig[operadora].campo_rg;
  },
  isActvated: state => state.activated,
  isConsultStatus: state => state.consultStatus,
  getStatusRetomada: state => state.lead.retomada ?? false,
};

export const mutations = {
  [Types.CLEAR_PERSONAL_DATA]: state => {
    state.lead = {};
    state.endereco = {};
    state.statusEndereco = false;
    state.identificadorPedido = "";
  },
  [Types.SET_ORDER_ID]: (state, orderId) => {
    state.identificadorPedido = orderId;
  },
  [Types.SET_ORDER_PERSONAL_DATA]: (state, lead) => {
    state.lead = { ...state.lead, ...lead };
  },
  [Types.SET_ADDRESS]: (state, address) => {
    state.endereco = address;
  },
  [Types.SET_EXPIRATION_DAY]: (state, diaVencimento) => {
    state.diaVencimento = diaVencimento;
  },
  [Types.SET_BILL_METHOD__DEBITO]: state => {
    state.formaPagamento = "Débito automático";
  },
  [Types.SET_BILL_METHOD__BOLETO]: state => {
    state.formaPagamento = "Boleto digital";
  },
  [Types.SET_MODALITY]: (state, modalidade) => {
    state.modalidade = modalidade;
  },
  [Types.SET_PRODUCT]: (state, product) => {
    state.produto = { ...product };
  },
  [Types.SET_ACTIVATED]: (state, activated) => {
    state.activated = activated;
  },
  [Types.SET_CONSULT_STATUS]: (state, activated) => {
    state.consultStatus = activated;
  },
  [Types.SET_CONFIG_BILL_INFO]: (state, configs) => {
    state.billConfig = configs;
  },
};

export const actions = {
  clearData: ({ commit }) => commit(Types.CLEAR_PERSONAL_DATA),
  setOrderId: ({ commit }, orderId) => commit(Types.SET_ORDER_ID, orderId),
  setPersonalData: ({ commit, rootGetters }, lead) => {
    let {
      dadosAdicionais: { cidade, uf, ddd },
    } = rootGetters.getQuery;
    commit(Types.SET_ORDER_PERSONAL_DATA, { ...lead, cidade, uf, ddd });
  },
  setRetomadaName: ({ commit }, nome) => {
    commit(Types.SET_ORDER_PERSONAL_DATA, { nome });
  },
  setAddress: ({ commit }, endereco) => {
    commit(Types.SET_ADDRESS, endereco);
  },
  setExpirationDay: ({ commit }, diaVencimento) => {
    commit(Types.SET_EXPIRATION_DAY, diaVencimento);
  },
  setBillMethod: ({ commit }, isDebit) => {
    if (isDebit) commit(Types.SET_BILL_METHOD__DEBITO);
    else commit(Types.SET_BILL_METHOD__BOLETO);
  },
  setModality: ({ commit }, modalidade) =>
    commit(Types.SET_MODALITY, modalidade),
  setProduct: ({ commit }, order) => {
    commit(Types.SET_PRODUCT, order);
  },
  setActivated: ({ commit }, payload) => {
    commit(Types.SET_ACTIVATED, payload);
  },
  setConsulStatus: ({ commit }, payload) => {
    commit(Types.SET_CONSULT_STATUS, payload);
  },
  setBillConfig: ({ commit }, payload) => {
    commit(Types.SET_CONFIG_BILL_INFO, payload);
  },
};
