<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    size: {
      type: Number,
      default: 4,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
  },
  computed: {
    height() {
      return `${this.size * 0.25}rem`;
    },
    width() {
      return `${this.size * 0.25}rem`;
    },
  },
  // methods: {
  //   getSize: number => number * 0.25,
  // },
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    :width="width"
    viewBox="0 0 16 16"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>
