export default {
  ConsultaCobertura: 1,
  SemCobertura: 1,
  SemCoberturaLeadEnviado: 1,
  EscolhaPlano: 1,
  DadosPessoais: 2,
  Endereco: 3,
  Pagamento: 4,
  ComplementarDados: 4,
};
