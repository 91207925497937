import getErrorsObject from "~/src/utils/get-errors-object";

const Types = {
  CLEAN_STATE: "CLEAN_STATE",
  SET_TYPE: "SET_TYPE",
  CHANGE_STATE: "CHANGE_STATE",
  SET_INFOS: "SET_INFOS",
  SET_PORTFOLIO: "SET_PORTFOLIO",
  SET_ERROR_OBJ: "SET_ERROR_OBJ",
  SET_ERRORMESSAGE: "SET_ERRORMESSAGE",
};
export const state = {
  isOpen: false,
  error: 1,
  infos: {
    price: 999.99,
    dados: "",
    newSku: "",
    errorMessage: "",
  },
  portfolio: {
    controle: [
      {
        id_plano: 0,
        tipo_plano: null,
        tipo_oferta: null,
        tipo_combo: "SINGLE",
        simulador: null,
        sku: "OICT001CM",
        nome: "Oi Mais Controle Básico 6GB",
        info_plano: {
          sku: "OICT001CM",
          tipo_plano: "CONTROLE",
          produto: "OI CONTROLE",
          titulo: "Ligações Ilimitadas",
          apps: {
            total: 2,
            imagens: ["messenger.svg", "whatsapp.svg"],
            titulo: "Apps sem descontar da Internet:",
          },
          apps_large: null,
          plano: "Oi Mais Controle Básico 6GB",
          IdCrm: "",
          valor_recarga: 0,
          Id_m4u: "OCSF110",
          Plano_operadora: "",
          Promocao_operadora: "",
          dados: "6GB",
          url_img_bot:
            "https://automatuslab.blob.core.windows.net/macro/Oi/Chat-Bot/Controle/OI_Controle_chatbot_Cartao_6GB.jpeg",
          url_contrato_bot: "http://bit.ly/2OSw3Cj",
          campanha: "default",
          dados_detalhe: "6GB + Ligações ilimitadas",
          apps_add: null,
          view: "FALSE",
          anual: "FALSE",
          descricao: "para qualquer celular e fixo do Brasil",
          tipo_fatura: "Cartão",
          texto_sidebar: "*No cartão de crédito",
          texto_pagamento: {
            cartao:
              "Com esta opção você ganha um desconto mensal de R$5,00 e não se preocupa com a renovação dos seus benefícios!",
            boleto:
              "Utilize seus créditos para fazer a adesão do plano. O valor promocional de R$39.99 é válido apenas no primeiro mês após R$44.99 no Boleto Digital.",
          },
          detalhe: [
            "Apps ilimitados SEM GASTAR SUA INTERNET: Whatsapp e Messenger;",
            "Ligações ilimitadas para qualquer celular e fixo do Brasil;",
            "1000 SMS para qualquer operadora;",
            "Apps de conteúdo exclusivo: Oi Jornais, Revista Coquetel, Portal Consumidor Inteligente e Clic News.",
          ],
        },
        valores_plano: {
          valor_oferta: 44.99,
          taxa_instalacao: 0,
          desconto: 5,
          valor_com_desconto: 39.99,
        },
      },
    ],
    pos: null,
  },
};

export const getters = {
  getState: state => state,
  getInfos: state => {
    const seachResult = getErrorsObject(state.error, state.infos.errorMessage);
    const errorElse = {
      closeBtnIsVisible: true,
      icon: false,
      title: `Poxa, o plano escolhido não está disponível para o DDD da sua linha.`,
      description: `Mas não se preocupe, temos uma oferta especial pra você! <b>Contrate</b> agora mesmo o plano de <b>${
        state.infos.dados
      }</b> no valor mensal de <b>${state.infos.price.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}</b>.`,
      tracking: {},
      ctas: [
        {
          link: `https://www.assineoicontrole.com.br/?ddd=${state.infos.ddd}`,
          target: "_self",
          title: "ESCOLHER OUTRO PLANO",
          isOutline: true,
        },
        {
          title: "CONTRATAR O PLANO",
          onClick: callbackFunc =>
            callbackFunc({
              Migracao: {
                sucesso: "PagamentoMovel",
                falha: "ComplementarDadosMovel",
              },
              NovaLinha: {
                sucesso: "EnderecoMovel",
                falha: "ComplementarDadosMovel",
              },
            }),
          isOutline: false,
        },
      ],
    };
    // if (state.infos.newSku === "") {
    if (!seachResult.notFound) return seachResult;
    else return errorElse;
    // } else {
    //   return errorElse;
    // }
  },
  getPortfolio: state => state.portfolio,
  getErrorObj: state => state.erroObj,
};

export const mutations = {
  [Types.SET_TYPE]: (state, type) => (state.error = type),
  [Types.CHANGE_STATE]: state => (state.isOpen = !state.isOpen),
  [Types.SET_INFOS]: (state, payload) => (state.infos = { ...payload }),
  [Types.SET_ERROR_OBJ]: (state, payload) => (state.erroObj = payload),
  [Types.SET_PORTFOLIO]: (state, portfolio) =>
    (state.portfolio.controle = [...portfolio]),
  [Types.SET_ERRORMESSAGE]: (state, errorMessage) => {
    state.infos.errorMessage = errorMessage;
  },
  [Types.CLEAN_STATE]: state => (state.isOpen = false),
};

export const actions = {
  resetModal({ commit }) {
    commit(Types.CLEAN_STATE);
  },
  setError({ commit }, error) {
    commit(Types.SET_TYPE, error);
  },
  setErrorMessage({ commit }, errorMessage) {
    commit(Types.SET_ERRORMESSAGE, errorMessage);
  },
  closeModal({ commit }) {
    commit(Types.CHANGE_STATE);
  },
  setState({ commit }, params = {}) {
    if (params.infos && params.portfolio) {
      commit(Types.SET_INFOS, params.infos);
      commit(Types.SET_PORTFOLIO, params.portfolio);
    }
    if (params.msg) commit(Types.SET_ERROR_OBJ, params);
    commit(Types.CHANGE_STATE);
  },
};
