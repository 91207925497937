<script>
import axios from "axios";
import ALoading from "@components/atoms/a-loading.vue";
export default {
  components: { ALoading },
  data() {
    return {
      offlineConfirmed: false,
    };
  },
  beforeCreate() {
    axios
      .head("/")
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        this.offlineConfirmed = true;
      });
  },
};
</script>

<template>
  <div v-if="offlineConfirmed">
    <h1 :class="$style.title">
      A página atingiu o tempo limite durante o carregamento. Tem certeza de que
      ainda está conectado a a Internet?
    </h1>
  </div>
  <div v-else class="loading">
    <a-loading />
  </div>
</template>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
