import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress/nprogress";

import store from "@state/store";
import positions from "@router/bread-positions";

import routes from "./routes";

// import eventTrackGuard from "./event-track.guard";

Vue.use(VueRouter);
NProgress.configure({ showSpinner: false });

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((routeTo, routeFrom, next) => {
  const query = routeTo.query;
  if (routeFrom.name !== null) {
    NProgress.start();
  }
  if (!store.getters["getIsStoreStarted"] && routeTo.path !== "/") {
    next({ name: "Checkout", query: query });
  }
  // eventTrackGuard(routeTo, routeFrom);
  if (routeTo.name === "Parabens" || routeTo.name === "ParabensMovel") {
    next();
  }
  if (
    routeFrom.name === "Parabens" ||
    routeFrom.name === "ParabensMovel" ||
    routeFrom.name === "ParabensChatMovel"
  ) {
    NProgress.done();
    next({ name: routeFrom.name });
  } else next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              if (routeFrom.name === args[0].name) {
                NProgress.done();
              }
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }
  next();
});

router.afterEach(to => {
  setTimeout(() => {
    store.dispatch("breadcrumb/setCurrentPosition", positions[to.name]);
  }, 500);
  NProgress.done();
});

export default router;

//url chat_bot -> /?IdentificadorPedido=02V254LM20&utm_source=facebook&utm_campaign=oi_controle&utm_medium=chat_bot
